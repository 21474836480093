#call-type-identifier.IB {
  background-color: #009afa;
}
#call-type-identifier.QCB {
  background-color: #f9f871;
}

#call-type-identifier.MAN,
#call-type-identifier.OB {
  background-color: #e29e21;
}

#call-type-identifier {
  margin: 0rem .5rem 0rem .5rem;
  padding: 0rem;
}



#call-type-identifier .card-header {
  padding: 0.5rem;
}


#call-type-identifier .card-header h5 {
  border: none;
}
