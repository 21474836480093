#column_2 {
  padding: 0.4rem;
}

#column_2 div.container-fluid {
  padding: 0.2rem;
  max-height: 580px;
  overflow: hidden;
}

#column_2 .info-row > div {
  overflow-x: hidden;
  white-space: nowrap;
  
}

#column_2 div.container-fluid .tab-pane.active {
  max-height: 530px;
  overflow: auto;
}

#column_2.cc-single-column div.container-fluid {
  padding: 0.2rem;
  max-height: auto;
  overflow: visible;
}
#column_2.cc-single-column div.container-fluid .tab-pane.active {
  max-height: auto;
  overflow: visible;
}
