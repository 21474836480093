
#column_2 .nav-tabs {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: .1rem .5rem 0rem .5rem;
  margin-bottom:.3rem!important;
  background-color:rgba(197, 200, 204, 0.562);
}

#column_2 .nav-tabs .nav-item .nav-link {
  font-weight: bold;
}

#column_2 .accordion {
  border-bottom: 1px solid rgba(0,0,0,.125);
}


h5 {
  font-weight: bold;
  margin-bottom: 0.24rem;
  font-size: 1.1rem;
}
.table tr td {
  border-top: none !important;
  padding: 0rem;
  padding-bottom: 0.15rem;
  font-size: 0.9rem;
}

.table tr td:first-child {
  width: 150px;
  padding-left: 0.5rem;
  font-size: 0.9rem;
  text-align: left;
  padding-bottom: 0.15rem;
  font-weight: normal;
}

input.form-control.text {
  background-color: white;
  padding: 0.2rem;
  padding-left: 0.5rem;
  font-size: 0.9rem;
  height: calc(1.2em + 0.2rem + 2px);
  color: black;
  line-height: 1.2;
}

.info-row .form-label {
  margin-top: .1rem;
  margin-bottom: 0rem;
  font-size: 0.85rem;
  text-align: left;
  font-weight: normal;
}



