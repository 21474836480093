#verbal_complaint {
  width: 500px;
}

div#verbal_complaint_footer {
  border-top: 1px solid rgba(0, 0, 0, .25);
}
#verbal_complaint .offcanvas-header {
  background-color: #003b5d;
  color: white;
}

#verbal_complaint .cc-vc-description {
  font-size: .8rem;
  color: darkgray;

}
#verbal_complaint .form-check {
  font-size: .85rem;
}

#verbal_complaint .cc-vc-collapse .cc-vc-description,
#verbal_complaint .cc-vc-collapse .unchecked.form-check,
#verbal_complaint .cc-vc-collapse .unchecked.btn {
  display: none!important;
}

#verbal_complaint .btn-group .btn {
  width: auto; 
  font-size: .85rem;
  padding: .15rem;
  border-radius: 4px!important;
  margin: 3px;
  font-size:.9rem;
  /* white-space: nowrap; */
  max-width: 445px; 
}

#verbal_complaint .character-count {
  font-size: .7rem;
  color: grey;
}

#verbal_complaint .character-count.error-red {
  color:red;
}

#verbal_complaint .form-control.error-red {
  border-color: red;
}