#column_1 {
  padding: .4rem;
}

.field-display div.card {
  padding: 0rem .5rem 0rem .5rem;
  border:none;

}
/* #contact-information div.card:nth-child(2) div.card-body,
.field-display div.card:first-child div.card-body {
  border-top: 1px solid rgba(0, 0, 0, 0);
  padding: .25rem 0rem 0rem 0rem;

} */
.field-display .row {
  margin-left:0px;
  margin-right:0px;
  margin-bottom:.1rem;
}


.field-display div.card div.card-body {
  padding: .25rem 0rem 0rem 0rem;

}
.field-display h5 {
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, .25);
  margin-bottom: 0.24rem;
  font-size: 1.1rem;
}

.field-display .row .info-row {
    padding-left: .2rem;
    padding-right: .2rem;
}


.address-toggle {
  border-bottom: 1px solid rgba(0, 0, 0, .25);
  margin-bottom: 0.24rem;
}


.address-toggle .btn-check {
  border-bottom: 0px!important;
}
.address-toggle .btn {
  padding: 0px;
  margin-right: .5rem;
  border:0px;
  border-radius: 0px;
  font-size: 1.1rem;
  color: darkgrey
}

.address-toggle .btn.active {
  color:black;
  font-weight: bold;
  border-bottom:1px solid black;

}
.address-toggle .btn-check:active+.btn,
.address-toggle .btn-check:focus+.btn {
  outline: none!important ;
  box-shadow: none!important;
}



/* .field-display .table {
  margin-bottom: .3rem;
}
.field-display .table tr td {
  border-top: none !important;
  padding: 0rem;
  padding-bottom: 0.15rem;
  font-size: 0.9rem;
}

#contact-information .table tr td:first-child {
  width: 150px;
  font-size: 0.9rem;
  text-align: left;
  font-weight: normal;
}

#contact-information .table tr td:last-child {
  width: 30px;
  margin-right: 5px;
  padding-bottom: 0.1rem;
  text-align: right;
  font-weight: bold;
} */




.field-display input.form-control-sm {
  padding: 0.1rem!important;
  padding-left: 0.5rem;
  font-size: 0.9rem;
  height: calc(1.15em + 0.1rem + 2px);
  color: black;
  line-height: 1.1;
  min-height: calc(1.15em + .5rem + 2px);
  
}
.info-row button {
  padding: 0.1rem!important;
  padding-left: 0.5rem;
  font-size: 0.9rem;
  height: calc(1.15em + 0.1rem + 2px);

  line-height: 1.1;
  min-height: calc(1.15em + .5rem + 2px);
}



.copy-btn {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  /* background-image: url("../../img/copy.png"); 
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 15px;*/
  font-size: 1rem !important;
  line-height: .8;
  padding:0px;
  cursor: pointer;
}
