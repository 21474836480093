
#contact_verfication {
  min-width: 800px;
  max-height: 50vh;
}

#contact_verfication .modal-header {
  background-color: #003b5d;
  color: white;
}
#contact_verfication label.form-label {
  font-size: .9rem!important;
}

#contact_verfication .accordion {
  overflow-y: auto;
}

#contact_verfication .accordion .accordion-header button {
  font-size: .92rem!important;
  font-weight: bold;
  padding: .5rem .7rem;

}
#contact_verfication .accordion .accordion-item .accordion-body {
  padding:.5rem;
}
#contact_verfication .accordion .accordion-item .accordion-body p {
  font-size: .9rem!important;
  margin-bottom:0px;
}


/* #contact_verfication .lcom-buttons button {
  margin: .2rem .4rem;
} */