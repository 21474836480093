.script-accordion h2.accordion-header  button.accordion-button{
  font-size: 1rem!important; 
  font-weight: bold;
  padding: .6rem;
}

.script-accordion .accordion-body {
  padding: .5rem;
  font-size: .9rem;
}

.script-accordion .accordion-body ul li ul {
  padding-left: 1rem;
}