#transfer_assist_modal .modal-header {
  background-color: #003b5d;
  color: white;
  padding: .5rem 1rem;
}

#transfer_assist_modal {
  width: 500px;
}

#transfer_list .modal-body input[type="search"] {
  border-right: none;
} 

#transfer_list .dropdown-menu {
  padding: 0px;
  width: 100%;
  max-height: 300px;
  margin-left: -140px;
  margin-top: -1px;
  overflow: auto;
  font-size: .95rem;
}

#transfer_list .dropdown-menu div.dropdown-header{
  font-size: 0.9rem;
  font-weight: normal;
  color: rgba(128, 128, 128, 1);
  padding: 3px 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(242, 242, 242, 1);
}
#transfer_list .dropdown-menu a.dropdown-item{
  display: block;
  padding: 3px 8px;
  color: black;
}


#transfer_assist_modal #freedom_state_code_selector button {
  border-radius: 4px;
  padding: .25rem;
  font-size: .9rem;
}