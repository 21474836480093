
.incentive-worksheets .card-body {
  padding: 0rem;
}
.incentive-worksheets .card-body .selection-made span {
  display:block;
}

.incentive-worksheets .card-body .selection-made .btn-group label.unchecked {
  display: none!important;
}  

.incentive-worksheets .btn-group .btn {
  width: auto; 
  padding: .25rem;
  border-radius: 4px!important;
  margin: 3px;
  font-size:.9rem;
  white-space: nowrap; 
}
