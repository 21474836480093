/* ticker */
footer {
  position: fixed;
  bottom: 0;
  left: 0px;
  right: 0px;
  height: 2rem;
  background-color: #6c7888;
  display: flex;
  align-content: space-between;
  z-index: 1000;
  overflow-x: hidden;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {

  width: 100%;
  overflow: hidden;
  height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 100%;
  box-sizing: content-box;
  font-family: Calibri;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 2rem;
  line-height: 1;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
}

.ticker-wrap .ticker .ticker_item a {
  color: white;
  font-weight: bold;
}

.ticker-wrap .ticker .ticker_item {
  display: inline-block;
  padding: 0.4rem 3rem;
  font-size: 1.2rem;
  color: white;
}