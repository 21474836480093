#websocket_search_column {
  overflow-y: scroll;
  max-height: 85vh;
}

.websocket-columns .card-body {
  overflow-y: auto;
  max-height: 76vh;
  padding: 0px
}

.websocket-columns .card-body textarea {
  width:100%;
  overflow: auto;
  height: 75vh;
  border: none;
  font-family: 'Courier New', monospace;
  font-size: .9rem;
}