.worksheet_section_check {
  margin-left: .5rem;

}


.single-line-buttons div.btn-group {
  display:block;
  margin-top: .5rem;
  margin-bottom: .5rem;
  
}

.single-line-buttons div.btn-group input.disabled {
  color: black!important;
}
.single-line-buttons div.btn-group .btn:nth-child(2).disabled {
  width: 8.5rem;
  text-align: left;
  background-color:rgba(0,0,0,0);
  color: black!important;
  font-weight:bold;
  border: none;
}

.single-line-buttons div.btn-group .btn:nth-child(4) {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;

}

.btn-link.script-launch {
  margin-top: -.25rem!important;
  margin-left: 1rem!important;
  font-weight: bold!important;
  text-decoration: none!important;
}
