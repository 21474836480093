div[aria-labelledby="dropdown-quickdraw"] a.dropdown-item {
  padding-top:.2rem;
  padding-bottom:.2rem;
  font-size: .9rem;
}

div[aria-labelledby="dropdown-quickdraw"] div.dropdown-header {
  padding-bottom:.05rem;
  padding-top:.09rem;
}
span.badge {

}