#safe_haven_callback {
  width: 500px;
}

div#safe_haven_callback_footer {
  border-top: 1px solid rgba(0, 0, 0, .25);
}
#safe_haven_callback .offcanvas-header {
  background-color: #003b5d;
  color: white;
}

#safe_haven_callback .cc-vc-description {
  font-size: .8rem;
  color: darkgray;

}
#safe_haven_callback .form-label {
  font-size: .85rem;
  margin-bottom: 0px;
}
#safe_haven_callback .form-control,
#safe_haven_callback select {
  font-size: .85rem;
}

#safe_haven_callback .cc-vc-collapse .cc-vc-description,
#safe_haven_callback .cc-vc-collapse .unchecked.form-check,
#safe_haven_callback .cc-vc-collapse .unchecked.btn {
  display: none!important;
}

#safe_haven_callback .btn-group .btn {
  width: auto; 
  font-size: .85rem;
  padding: .15rem;
  border-radius: 4px!important;
  margin: 3px;
  font-size:.9rem;
  /* white-space: nowrap; */
  max-width: 445px; 
}
#safe_haven_callback .validation-failed {
  border-color: red;
}
#safe_haven_callback .character-count {
  font-size: .7rem;
  color: grey;
}

#safe_haven_callback .sh-required-error {
  color:red;
}

#safe_haven_callback .form-control.error-red {
  border-color: red;
}