#column_3 {
  padding: .4rem
}

#column_3 div.container-fluid {
  padding: .01rem;
  max-height: 85vh;
  font-size:.9rem;
}

#column_3 #worksheet_wrapper ul.nav.nav-pills {
  padding:4px;
  margin: 4px!important;
}
#column_3 #worksheet_wrapper .tab-content .card-body {
  max-height: 50vh;
  overflow: auto
}

#column_3 #worksheet_wrapper .accordion-button {
  padding: .4rem;
  color:black;
  font-weight: bold;
  font-size: 1.2rem;

}
#column_3 #worksheet_wrapper .accordion-body {
  padding: .5rem;

}

#worksheet_wrapper > .card-header {
  background-color: #6c7888;
  color: white;
  text-align: center;
}

#worksheet_wrapper > .card-header > .card-title {
 margin-bottom: 0px;
}

#worksheet_wrapper ul.nav.nav-pills li.nav-item button.btn-warning {
  background-color:#ffc107!important;
  color: white;
  border: 1px solid #ffc107;
}

#worksheet_wrapper ul.nav.nav-pills li.nav-item button.btn-warning.active {
  background-color:white!important;
  color: #ffc107;
  border: 1px solid #ffc107;
}
#worksheet_wrapper ul.nav.nav-pills li.nav-item button {
  margin-right: .2rem;
  padding: .25rem .5rem;
}


#worksheet_wrapper ul.nav.nav-pills li.nav-item button.btn-primary {
  background-color:#0d6efd!important;
  color: white;
  border: 1px solid #0d6efd;
}

#worksheet_wrapper ul.nav.nav-pills li.nav-item button.btn-primary.active {
  background-color:white!important;
  color: #0d6efd;
  border: 1px solid #0d6efd;
}

#worksheet_wrapper ul.nav.nav-pills li.nav-item button.btn-secondary {
  background-color:#6c757d!important;
  color: white;
  border: 1px solid #6c757d;
}

#worksheet_wrapper ul.nav.nav-pills li.nav-item button.btn-secondary.active {
  background-color:white!important;
  color: #6c757d;
  border: 1px solid #6c757d;
}