#worksheet_nav_wrapper {
  border-bottom: 1px solid rgba(0,0,0,.125);

}
#worksheet_nav_wrapper .nav-item a {
  padding: 5px!important;
  margin: 4px;
  cursor: pointer;
  background-color: #0d6efd;
  color:white!important;
  border-radius: 4px;
  border: 1px solid #0d6efd;
}

#worksheet_nav_wrapper .nav-item a.active {
  background-color: white;
  color:#0a58ca!important;
  border: 1px solid #0d6efd;
}

#worksheet_nav_wrapper .nav-item a.incentive {
  background-color: #ffc107;
  color:white!important;
  border: 1px solid #ffc107;
}

#worksheet_nav_wrapper .nav-item a.incentive.active {
  background-color: white;
  color:#ffc107!important;
  border: 1px solid #ffc107;
}

#worksheet_nav_wrapper .nav-item a.incentive-complete {
  background-color: #6c757d;
  color:white!important;
  border: 1px solid #6c757d;
}

#worksheet_nav_wrapper .nav-item a.incentive-complete.active {
  background-color: white;
  color:#6c757d!important;
  border: 1px solid #6c757d;
}